/*-------------------------
   Counter Up
-------------------------*/
.counterup-progress {
    background: transparent;
    border: 1px solid;
    border-image-source: linear-gradient(0deg, #27272E 64.45%, rgba(74, 75, 86, 0.72) 100%);
    text-align: center;
    padding: 70px 20px 60px;
    border-radius: 30px;
    transition: var(--transition);
    margin-bottom: 30px;
    @media #{$sm-layout} {
        padding: 40px 20px;
    } 
    @media #{$large-mobile} {
        padding: 30px 10px;
        
    }
    .icon {
        margin-bottom: 20px;
    }
    .count-number {
       font-weight: 500;
    //    color: var(--color-primary);
        color: #fec89a;
       font-family: var(--font-primary);
       margin-bottom: 10px;
       display: flex;
       align-items: center;
       justify-content: center;
       @media #{$sm-layout} {
        letter-spacing: 0;
       }
    }
    .title {
        color: var(--color-white);
        margin-bottom: 10px;
    }
    .crazy-sub-title {
        margin: 0;
        padding: 0;
        font-size: 14px;
        color: #ffffff33 !important;
    }
    &:hover, &.active {
        // background: var(--gradient-dark);
        // background: #00000011;
        background: #ffffff11;
    }
    &.counterup-style-2 {
        background: var(--gradient-primary);
        border: none;
        transition-duration: 2s;
        .icon {
            .light-icon {
                display: none;
            }
        }
        .title {
            color: var(--color-body);
        }
        &:hover, &.active {
            background-color: var(--color-light);
        }
    }
}


.expert-counterup-area {
    .section-heading {
        p {
            width: 100%;
        }
        .axil-btn {
            width: 240px;
        }
    }
}

.platform-hr {
    opacity: 1;
    height: 2px;
    width: 100%;
    background-color: #ffffff11;
}

.platform-review {
    margin-top: 50px;
    padding-bottom: 80px;
}

.platform-review-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .platform-review-top-container {
        height: 150px;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: url('/images/others/star-rating.svg');

        .review-digit {
            margin-top: 20px;
            font-size: 40px;
            color: #fec89a;
        }
        .review-out-of {
            font-size: 20px;
            color: #fec89a;
        }
    }
    .platform-review-mid-container {
        margin-top: 30px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
    }
    .platform-review-bottom-container {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .platform-attributes {
            margin: 0 0 10px 0;
            color: #ffffff33;
            font-weight: 400;
            font-family: Poppins, sans-serif;
            font-size: 14px;
        }
    }
}