/*-------------------------
  Helper Classes
-------------------------*/

.bg-color-dark {
    // background-color: var(--color-dark);
    background: linear-gradient(0deg, #231942, #14213d);
}

.bg-color-light {
    background-color: var(--color-light);
}

.bg-color-mercury {
    background-color: var(--color-mercury);
}

.bg-color-ship-gray {
    background-color: var(--color-ship-gray);
}

// Color
.color-gray-4 {
    color: var(--color-gray-4);
}

// Opacity
.opacity-50 {
    opacity: 0.5;
}