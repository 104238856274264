.reviews-holder {
    margin-top: 40px;
}

.review-container.freelancer {
    background: linear-gradient(45deg, #231942, #0077b6);
}
.review-container.upwork {
    background: linear-gradient(45deg, #231942, #73d707);
}
.review-container.fiverr {
    background: linear-gradient(45deg, #231942, #00a459);
}
.review-container {
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 250px;
    box-shadow: 0 55px 86px -35px #23194277;
    border-radius: 30px;
    transition: 0.25s;
    
    .review {
        text-align: center;
        color: #fff;
    }
    
    img {
        transform: scale(0.75);
        position: absolute;
        bottom: 20px;
        transition: 0.25s;
        opacity: 0.5;
    }
    
    &:hover {
        transform: scale(1.02, 1.02);
        box-shadow: 0 55px 86px -35px #231942aa;
        img {
            opacity: 1;
        }
    }
}
